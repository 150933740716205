<template>
    <div class="container-fluid bg-login">
        <a-row>
            <a-col :span="24">
                <div class="form-login">
                    <a-form>
                        <a-card :bordered="false"
                                class="login-form"
                                title="Selecteer tenant & netwerk"
                        >
                          <a-icon @click="$router.back();" type="arrow-left"  slot="extra" />
                            <a-form-item label="Tenants" class="mb-0">
                                <a-select showSearch placeholder="Selecteer een tenant"
                                          style="width: 100%" @change="handleChangeTenant"
                                          size="large" :filterOption="filterOption"
                                          @focus="disableNetworks"
                                >
                                    <a-select-option v-for="tenant in tenants"
                                                     :value="tenant.id"
                                                     :key="tenant.id"
                                    >
                                      {{ tenant.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item label="Netwerken">
                                <a-select showSearch placeholder="Selecteer een netwerk"
                                          :disabled="networks_disabled"
                                          :loading="networks_loading"
                                          style="width: 100%"
                                          @change="handleChangeNetwork"
                                          size="large"
                                          :filterOption="filterOption"
                                >
                                    <a-select-option v-for="network in networks"
                                                     :value="network.id"
                                                     :key="network.id"
                                    >
                                      {{ network.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-card>
                    </a-form>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>

import '../../plugins/ant-design-vue';
import { mapActions } from 'vuex';
import User from '@/api/user';
import axios from '@/plugins/axios';
import EventBus from '@/events/event-bus';

export default {
  name: 'auth.switcher',
  data() {
    return {
      loading: false,
      user: [],
      tenants: [],
      loggedIn: false,
      selectedTenantId: null,
      selectedNetworkId: null,
      networks_disabled: true,
      networks_loading: false,
      networks: [],
    };
  },
  watch: {
    user() {
      User.getTenants(this.user.id)
        .then((response) => {
          this.tenants = response.data.sort((a, b) => a.name.localeCompare(b.name));
        });
    },
  },
  methods: {
    ...mapActions({
      resetCachedMap: 'map/resetMap',
    }),
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0;
    },
    handleChangeTenant(id) {
      axios.defaults.headers['x-tenant'] = id;
      this.selectedTenantId = id;
      this.networks_loading = true;
      User.getNetwork(this.user.id)
        .then((response) => {
          this.networks = response.data;
          this.networks_loading = false;
          this.networks_disabled = false;
        })
        .catch(() => {
          this.networks_loading = false;
        });
    },
    disableNetworks() {
      this.networks_disabled = true;
    },
    async handleChangeNetwork(id) {
      this.selectedNetworkId = id;
      axios.defaults.headers['x-network'] = this.selectedNetworkId;
      await this.$store.dispatch('switcher/setTenantId', this.selectedTenantId);
      await this.$store.dispatch('switcher/setNetworkId', this.selectedNetworkId);
      await this.resetCachedMap();
      EventBus.$emit('Map.clearMap');
      await this.$store.dispatch('settings/fetchNetwork');
      await this.$store.dispatch('settings/forceFetchAllNetwork');
      await this.$store.dispatch('selected/resetMapObject');
      await this.$store.dispatch('selected/resetSelection');
      await this.$store.dispatch('getUser');
      this.$emit('showFullscreen', false);
      // EventBus.$emit('Map.redraw');
      await this.$router.push({ name: 'home' });
    },
  },
  beforeMount() {
    this.$emit('showFullscreen', true);
  },
  mounted() {
    User.getMe()
      .then((response) => {
        this.loggedIn = true;
        this.user = response.data;
      })
      .catch(() => {
        this.$router.push({ name: 'login' });
      });
  },
};
</script>
