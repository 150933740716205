/* eslint-disable no-shadow,no-param-reassign,no-return-assign,no-sequences */
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import networkApi from '@/api/network';

import mapFunctions from '@/mixins/mapFunctions';
import EventBus from '@/events/event-bus';

const state = {
  updated_at: null,
  actions: {},
  actionImageTypes: {},
  actionPriorities: {},
  actionStatuses: {},
  attributes: {},
  attributeGroups: {},
  dynamicActionTypes: {},
  dynamicActionTypeCategories: {},
  keyValues: {},
  mapObjects: {},
  mapObjectCategories: {},
  mapObjectSelections: {},
  mapObjectSelectionTypes: {},
  mapObjectStatuses: {},
  mapObjectTypes: {},
  mapObjectTypeMapObjectStatuses: {},
  mapObjectTypeActionStatuses: {},
  teams: {},
  users: {},
  networkModel: null,
  preferredSaveButton: null,

  filter: {},

  loadingSettings: false,
  loadingActions: false,
  loadingMapObjects: false,
  loadingSelections: false,
  settingsLoadedAt: null,
  actionsLoadedAt: null,
  mapObjectsLoadedAt: null,
  selectionsLoadedAt: null,
};

const actions = {
  forceFetchAllNetwork({ commit, dispatch }) {
    commit('SET_ACTIONS_LOADED_AT', null);
    commit('SET_MAP_OBJECTS_LOADED_AT', null);
    commit('SET_SELECTIONS_LOADED_AT', null);
    commit('SET_SETTINGS_LOADED_AT', null);
    dispatch('fetchAllNetwork');
  },
  fetchAllNetwork({ dispatch }) {
    dispatch('fetchActions');
    dispatch('fetchMapObjects');
    dispatch('fetchSelections');
    dispatch('fetchSettings');
  },
  fetchSettings({ commit, state }) {
    if (state.settingsLoadedAt !== null && state.settingsLoadedAt > (Date.now() - (60000 * 10))) {
      return '';
    }
    if (state.loadingSettings) {
      return '';
    }
    if (localStorage.getItem('network_id') === null) {
      return null;
    }
    commit('SET_LOADING_SETTINGS', true);
    return networkApi.getNetworkSettings(localStorage.getItem('network_id'))
      .then((response) => {
        console.log('Fetched Network settings');
        const actionImageTypes = response.actionImageTypes.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_ACTION_IMAGE_TYPES', actionImageTypes);

        const actionPriorities = response.actionPriorities.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_ACTION_PRIORITIES', actionPriorities);

        const actionStatuses = response.actionStatuses.data
          .sort((a, b) => (a.weight > b.weight ? 1 : -1))
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_ACTION_STATUSES', actionStatuses);

        const attributes = response.attributes.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_ATTRIBUTES', attributes);

        const attributeGroups = response.attributeGroups.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_ATTRIBUTE_GROUPS', attributeGroups);

        const dynamicActionTypes = response.dynamicActionTypes.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_DYNAMIC_ACTION_TYPES', dynamicActionTypes);

        const dynamicActionTypeCategories = response.dynamicActionTypeCategories.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_DYNAMIC_ACTION_TYPE_CATEGORIES', dynamicActionTypeCategories);

        commit('SET_KEY_VALUES', response.keyValues.data);

        const mapObjectCategories = response.mapObjectCategories.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_CATEGORIES', mapObjectCategories);

        const mapObjectSelectionTypes = response.mapObjectSelectionTypes.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_SELECTION_TYPES', mapObjectSelectionTypes);

        const mapObjectStatuses = response.mapObjectStatuses.data
          .sort((a, b) => (a.weight > b.weight ? 1 : -1)).reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_STATUSES', mapObjectStatuses);

        const mapObjectTypes = response.mapObjectTypes.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_TYPES', mapObjectTypes);

        const mapObjectTypeMapObjectStatuses = response.mapObjectTypeMapObjectStatuses.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_TYPE_MAP_OBJECT_STATUSES', mapObjectTypeMapObjectStatuses);

        const mapObjectTypeActionStatuses = response.mapObjectTypeActionStatuses.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_TYPE_ACTION_STATUSES', mapObjectTypeActionStatuses);

        const teams = response.teams.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_TEAMS', teams);

        const users = response.users.data.reduce(mapFunctions.reduceFunc, {});
        commit('SET_USERS', users);

        commit('SET_SETTINGS_LOADED_AT', Date.now());
        commit('SET_LOADING_SETTINGS', false);
        console.log('Committed all Network settings');
        return '';
      })
      .catch((e) => {
        console.log('there has been an error in fetchSettings!', e);
      });
  },
  fetchActions({ commit, state, dispatch }) {
    if (state.actionsLoadedAt !== null && state.actionsLoadedAt > (Date.now() - (60000 * 10))) {
      return '';
    }
    if (localStorage.getItem('network_id') === null) {
      return null;
    }
    if (state.loadingActions) {
      return '';
    }
    commit('SET_LOADING_ACTIONS', true);
    return networkApi.getNetworkActions(localStorage.getItem('network_id'))
      .then((response) => {
        console.log('Fetched Network actions', response);
        let resultData = null;
        if (response && response.data) {
          console.log('repsonse has data!', response);
          resultData = response.data;
        } else {
          console.log('repsonse has no data!', response);
          resultData = response;
        }
        const actions = resultData.reduce(mapFunctions.reduceFunc, {});
        commit('SET_ACTIONS', actions);

        if (state.loadingMapObjects === false) {
          dispatch('sortMapObjectsAndActions');
        }

        commit('SET_ACTIONS_LOADED_AT', Date.now());
        commit('SET_LOADING_ACTIONS', false);
        console.log('Committed all Network actions');
        return '';
      })
      .catch((e) => {
        console.log('there has been an error in fetchActions!', e);
      });
  },
  fetchMapObjects({ commit, state, dispatch }) {
    if (state.mapObjectsLoadedAt !== null
      && state.mapObjectsLoadedAt > (Date.now() - (60000 * 10))) {
      return '';
    }
    if (localStorage.getItem('network_id') === null) {
      return null;
    }
    if (state.loadingMapObjects) {
      return '';
    }
    commit('SET_LOADING_MAP_OBJECTS', true);
    return networkApi.getNetworkMapObjects(localStorage.getItem('network_id'))
      .then((response) => {
        console.log('Fetched Network data');

        const startTime = performance.now();
        const mapObjects = response;
        mapObjects.map((mapObject) => {
          mapObject.children = mapObjects.filter(item => item.parent_id === mapObject.id);
          return mapObject;
        });
        commit('SET_MAP_OBJECTS', mapObjects.reduce(mapFunctions.reduceFunc, {}));
        const endTime = performance.now();
        console.log(`MapObjects Mapping took ${endTime - startTime} milliseconds`);

        if (state.loadingActions === false) {
          dispatch('sortMapObjectsAndActions');
        }

        commit('SET_MAP_OBJECTS_LOADED_AT', Date.now());
        commit('SET_LOADING_MAP_OBJECTS', false);
        console.log('Committed all Network data');
        return '';
      })
      .catch((e) => {
        console.log('there has been an error in fetchMapObjects!', e);
      });
  },
  sortMapObjectsAndActions({ commit, state }) {
    console.log('sortMapObjectsAndActions');
    const actions = Object.values(state.actions);
    const groups = mapFunctions.groupBy(actions, 'map_object_id');
    const mapObjects = Object.values(state.mapObjects);
    mapObjects.map((mapObject) => {
      mapObject.actions = groups.get(mapObject.id);
      return mapObject;
    });
    commit('SET_MAP_OBJECTS', mapObjects.reduce(mapFunctions.reduceFunc, {}));
  },
  fetchSelections({ commit, state }) {
    if (state.selectionsLoadedAt !== null
      && state.selectionsLoadedAt > (Date.now() - (60000 * 10))) {
      return '';
    }
    if (localStorage.getItem('network_id') === null) {
      return null;
    }
    if (state.loadingSelections) {
      return '';
    }
    commit('SET_LOADING_SELECTIONS', true);
    return networkApi.getNetworkSelections(localStorage.getItem('network_id'))
      .then((response) => {
        console.log('Fetched Network selections');
        const mapObjectSelections = response.data
          .reduce(mapFunctions.reduceFunc, {});
        commit('SET_MAP_OBJECT_SELECTIONS', mapObjectSelections);

        commit('SET_SELECTIONS_LOADED_AT', Date.now());
        commit('SET_LOADING_SELECTIONS', false);
        console.log('Committed all Network selections');
        return '';
      })
      .catch((e) => {
        console.log('there has been an error in fetchSelections!', e);
      });
  },
  fetchNetwork({ commit }) {
    return networkApi.getNetworkById(localStorage.getItem('network_id'))
      .then((response) => {
        commit('SET_NETWORK', response.data);
        return response.data;
      })
      .catch(() => {
      });
  },
  setCurrentPreferredSaveButton({ commit }, payload) {
    return commit('SET_PREFERRED_SAVE_BUTTON', payload.button);
  },
  getChildrenForMapObject({ state, dispatch }, { id, onlyDirectChildren = false }) {
    const children = Object.values(state.mapObjects).filter(item => item.parent_id === id);
    if (!onlyDirectChildren) {
      children.map(async (child) => {
        const data = await dispatch('getChildrenForMapObject', { id: child.id });
        child.children = { data };
        return child;
      });
    }
    return children;
  },
  async getParentsForMapObject({ state, dispatch }, { mapObject }) {
    if (mapObject.parent_id === null) {
      return null;
    }
    const parentResult = Object.values(state.mapObjects).filter(
      item => item.id === mapObject.parent_id,
    );
    if (parentResult.length === null) {
      return null;
    }
    if (parentResult[0].parent_id !== null) {
      const upperParents = await dispatch('getParentsForMapObject', { mapObject: parentResult[0] });
      parentResult.concat(upperParents);
    }
    return parentResult;
  },
  getActionsForMapObjectId({ state }, { id }) {
    return Object.values(state.actions).filter(item => item.map_object_id === id);
  },
  getActionsForMapObjectIds({ state }, { ids }) {
    return Object.values(state.actions).filter(item => ids.includes(item.map_object_id));
  },
  async getRelatedForMapObjectId({ state }, { id }) {
    console.log('getRelatedForMapObjectId', id);
    let ids = [id];
    let object = Object.values(state.mapObjects).filter(item => item.id === id)[0];
    while (object && object.parent_id !== null) {
      ids.push(object.parent_id);
      // eslint-disable-next-line prefer-destructuring,no-loop-func
      object = Object.values(state.mapObjects).filter(item => item.id === object.parent_id)[0];
    }
    const children = Object.values(state.mapObjects)
      .filter(
        item => item.parent_id !== null && !ids.includes(item.id) && ids.includes(item.parent_id),
      )
      .map(item => item.id);

    ids = ids.concat(children);

    const lowerChildren = Object.values(state.mapObjects)
      .filter(
        item => item.parent_id !== null && !ids.includes(item.id) && ids.includes(item.parent_id),
      )
      .map(item => item.id);
    ids = ids.concat(lowerChildren);
    return ids;
  },
  setFilter({ commit }, { filter }) {
    commit('SET_FILTER', filter);
    // commit('RESET_MAP');
  },
  resetFilter({ commit }) {
    commit('SET_FILTER', {});
    // commit('RESET_MAP');
  },
  addAction({ commit, state }, { action }) {
    console.log('addAction', action);
    const actions = Object.values(state.actions);
    actions.push(action);
    commit('SET_ACTIONS', actions.reduce(mapFunctions.reduceFunc, {}));
    if (action.map_object_id !== null) {
      const { mapObjects } = state;
      if (!Object.hasOwn(mapObjects[action.map_object_id], 'actions')) {
        // eslint-disable-next-line
        mapObjects[action.map_object_id]['actions'] = [ action ];
      } else {
        mapObjects[action.map_object_id].actions.push(action);
      }
      commit('SET_MAP_OBJECTS', mapObjects);
      EventBus.$emit('Map.redrawMapObject', mapObjects[action.map_object_id]);
    } else {
      EventBus.$emit('Map.redrawActionOnMap', action);
    }
  },
  updateAction({ commit, state }, { action }) {
    const { actions } = state;
    actions[action.id] = action;
    commit('SET_ACTIONS', actions);
    if (action.map_object_id !== null) {
      const { mapObjects } = state;
      const actions = mapObjects[action.map_object_id].actions
        .filter(item => item.id !== action.id);
      actions.push(action);
      mapObjects[action.map_object_id].actions = actions;
      commit('SET_MAP_OBJECTS', mapObjects);
      EventBus.$emit('Map.redrawMapObject', mapObjects[action.map_object_id]);
    }
  },
  removeAction({ commit, state }, { actionId }) {
    const { actions } = state;
    const action = actions[actionId];
    delete actions[actionId];
    commit('SET_ACTIONS', actions);
    if (action.map_object_id !== null) {
      const { mapObjects } = state;
      const actions = mapObjects[action.map_object_id].actions
        .filter(item => item.id !== action.id);
      mapObjects[action.map_object_id].actions = actions;
      commit('SET_MAP_OBJECTS', mapObjects);
      EventBus.$emit('Map.redrawMapObject', mapObjects[action.map_object_id]);
    } else {
      EventBus.$emit('Map.removeActionFromMap', actionId);
    }
  },
  addMapObject({ commit, state }, { mapObject }) {
    const mapObjects = Object.values(state.mapObjects);
    mapObjects.push(mapObject);
    commit('SET_MAP_OBJECTS', mapObjects.reduce(mapFunctions.reduceFunc, {}));
  },
  updateMapObject({ commit, state }, { mapObject }) {
    console.log('settings.updateMapObject', mapObject);
    const { mapObjects } = state;
    mapObjects[mapObject.id] = mapObject;
    EventBus.$emit('Map.redrawMapObject', mapObject);
    commit('SET_MAP_OBJECTS', JSON.decode(JSON.encode(mapObjects)));
  },
  removeMapObject({ commit, state }, { mapObjectId }) {
    const { mapObjects } = state;
    const mapObject = mapObjects[mapObjectId];
    if (!mapObject) {
      return;
    }
    if (mapObject.parent_id !== null) {
      let parent = mapObjects[mapObject.parent_id];
      if (parent) {
        parent = parent.children.filter(item => item.id !== mapObjectId);
        mapObjects[mapObject.parent_id] = parent;
      }
    }
    delete mapObjects[mapObjectId];
    commit('SET_MAP_OBJECTS', JSON.decode(JSON.encode(mapObjects)));
    EventBus.$emit('Map.removeMapObjectFromMap', mapObjectId);
  },
};

const mutations = {
  SET_UPDATED_AT(state, updatedAt) {
    state.updated_at = updatedAt;
  },
  SET_ACTIONS(state, actions) {
    Vue.set(state, 'actions', actions);
  },
  SET_ACTION_IMAGE_TYPES(state, actionImageTypes) {
    Vue.set(state, 'actionImageTypes', actionImageTypes);
  },
  SET_ACTION_PRIORITIES(state, actionPriorities) {
    Vue.set(state, 'actionPriorities', actionPriorities);
  },
  SET_ACTION_STATUSES(state, actionStatuses) {
    Vue.set(state, 'actionStatuses', actionStatuses);
  },
  SET_ATTRIBUTES(state, attributes) {
    Vue.set(state, 'attributes', attributes);
  },
  SET_ATTRIBUTE_GROUPS(state, attributeGroups) {
    Vue.set(state, 'attributeGroups', attributeGroups);
  },
  SET_DYNAMIC_ACTION_TYPES(state, dynamicActionTypes) {
    Vue.set(state, 'dynamicActionTypes', dynamicActionTypes);
  },
  SET_DYNAMIC_ACTION_TYPE_CATEGORIES(state, dynamicActionTypeCategories) {
    Vue.set(state, 'dynamicActionTypeCategories', dynamicActionTypeCategories);
  },
  SET_KEY_VALUES(state, keyValues) {
    Vue.set(state, 'keyValues', keyValues);
  },
  SET_MAP_OBJECTS(state, mapObjects) {
    Vue.set(state, 'mapObjects', mapObjects);
  },
  SET_MAP_OBJECT_CATEGORIES(state, mapObjectCategories) {
    Vue.set(state, 'mapObjectCategories', mapObjectCategories);
  },
  SET_MAP_OBJECT_SELECTIONS(state, mapObjectSelections) {
    Vue.set(state, 'mapObjectSelections', mapObjectSelections);
  },
  SET_MAP_OBJECT_SELECTION_TYPES(state, mapObjectSelectionTypes) {
    Vue.set(state, 'mapObjectSelectionTypes', mapObjectSelectionTypes);
  },
  SET_MAP_OBJECT_STATUSES(state, mapObjectStatuses) {
    Vue.set(state, 'mapObjectStatuses', mapObjectStatuses);
  },
  SET_MAP_OBJECT_TYPES(state, mapObjectTypes) {
    Vue.set(state, 'mapObjectTypes', mapObjectTypes);
  },
  SET_MAP_OBJECT_TYPE_MAP_OBJECT_STATUSES(state, mapObjectTypeMapObjectStatuses) {
    Vue.set(state, 'mapObjectTypeMapObjectStatuses', mapObjectTypeMapObjectStatuses);
  },
  SET_MAP_OBJECT_TYPE_ACTION_STATUSES(state, mapObjectTypeActionStatuses) {
    Vue.set(state, 'mapObjectTypeActionStatuses', mapObjectTypeActionStatuses);
  },
  SET_TEAMS(state, teams) {
    Vue.set(state, 'teams', teams);
  },
  SET_USERS(state, users) {
    Vue.set(state, 'users', users);
  },
  SET_NETWORK(state, network) {
    Vue.set(state, 'networkModel', network);
  },
  SET_PREFERRED_SAVE_BUTTON(state, button) {
    state.preferredSaveButton = button;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_ACTIONS_LOADED_AT(state, timestamp) {
    state.actionsLoadedAt = timestamp;
  },
  SET_MAP_OBJECTS_LOADED_AT(state, timestamp) {
    state.mapObjectsLoadedAt = timestamp;
  },
  SET_SELECTIONS_LOADED_AT(state, timestamp) {
    state.selectionsLoadedAt = timestamp;
  },
  SET_SETTINGS_LOADED_AT(state, timestamp) {
    state.settingsLoadedAt = timestamp;
  },
  SET_LOADING_ACTIONS(state, loading) {
    state.loadingActions = loading;
  },
  SET_LOADING_MAP_OBJECTS(state, loading) {
    state.loadingMapObjects = loading;
  },
  SET_LOADING_SELECTIONS(state, loading) {
    state.loadingSelections = loading;
  },
  SET_LOADING_SETTINGS(state, loading) {
    state.loadingSettings = loading;
  },
};

const getters = {
  getUpdatedAt: state => state.updated_at,
  getActions: state => state.actions,
  getActionsAsArray: state => Object.values(state.actions),
  getActionImageTypes: state => state.actionImageTypes,
  getActionPriorities: state => state.actionPriorities,
  getActionStatuses: state => state.actionStatuses,
  getAttributes: state => state.attributes,
  getAttributeGroups: state => state.attributeGroups,
  getDynamicActionTypes: state => state.dynamicActionTypes,
  getDynamicActionTypeCategories: state => state.dynamicActionTypeCategories,
  getKeyValues: state => state.keyValues,
  getMapObjects: state => state.mapObjects,
  getMapObjectCategories: state => state.mapObjectCategories,
  getMapObjectSelections: state => state.mapObjectSelections,
  getMapObjectSelectionTypes: state => state.mapObjectSelectionTypes,
  getMapObjectStatuses: state => state.mapObjectStatuses,
  getMapObjectTypes: state => state.mapObjectTypes,
  getMapObjectTypeMapObjectStatuses: state => state.mapObjectTypeMapObjectStatuses,
  getMapObjectTypeActionStatuses: state => state.mapObjectTypeActionStatuses,
  getTeams: state => state.teams,
  getUsers: state => state.users,
  getNetworkModel: state => state.networkModel,
  getNetworkId: state => (state.networkModel ? state.networkModel.id : null),
  getPreferredSaveButton() {
    return state.preferredSaveButton;
  },
  getFilter: state => state.filter,
  getLoadingActions: state => state.loadingActions,
  getLoadingMapObjects: state => state.loadingMapObjects,
  getLoadingSelections: state => state.loadingSelections,
  getLoadingSettings: state => state.loadingSettings,
  getActionsLoadedAt: state => state.actionsLoadedAt,
  getMapObjectsLoadedAt: state => state.mapObjectsLoadedAt,
  getSelectionsLoadedAt: state => state.selectionsLoadedAt,
  getSettingsLoadedAt: state => state.settingsLoadedAt,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
