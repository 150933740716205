<template>
  <div id="main" :style="{'--module-color': `var(--${moduleColor})`}">
    <div class="loading-modal big" :class="{'open': loading}">
      <div id="page">
        <div id="phrase_box" style="height: 200px;">
          <CheckBoxIcon :checked="!loadingSettings" text="Netwerkconfiguratie inladen..." />
          <CheckBoxIcon :checked="!loadingActions" text="Acties inladen..." />
          <CheckBoxIcon :checked="!loadingMapObjects" text="Objecten inladen..." />
          <CheckBoxIcon :checked="!loadingSelections" text="Selecties inladen..." />
        </div>
      </div>
    </div>
    <Map v-if="!loadingNetwork && !loadingSettings && network !== null" :network="network"/>
    <div v-if="!loadingNetwork && !loading">
      <SideNav>
        <Module
          v-if="$can('module', 'routebeheer')
        || $can('module', 'beheerplus')"
          :nav="$t('module.route_management')"
          :title="$t('module.route_management') | capitalize"
          router-link="route_management"
        />
        <Module
          v-if="$can('module', 'tekenen')"
          :nav="$t('module.drawing')"
          :title="$t('module.drawing') | capitalize"
          router-link="drawing"
        />
        <Module
          v-if="$can('module', 'selecties')"
          :nav="$t('module.selection')"
          :title="$t('module.selection') | capitalize"
          router-link="selection"
        />
        <Module
          v-if="$can('module', 'publicatie')"
          :nav="$t('module.publication')"
          :title="$t('module.publication') | capitalize"
          router-link="publication"
        />
        <Settings/>
      </SideNav>
      <Drawer :nav="nav" :title="title" :fullscreen="fullscreen">
        <Loading :visible="loadingNetwork || loadingSettings"/>
        <slot v-if="!loadingNetwork && !loadingSettings"></slot>
      </Drawer>

      <div id="map-actions-float">
        <Search/>
        <MapFilter/>
      </div>
      <InfoModal/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '@/components/Layout/Drawer.vue';
import Module from '@/components/Layout/Module.vue';
import Search from '@/components/Layout/Search.vue';
import MapFilter from '@/components/Layout/MapFilter.vue';
import Settings from '@/components/Layout/Settings.vue';
import SideNav from '@/components/Layout/SideNav.vue';
import Map from '@/components/Map/LeafletMap.vue';
import Loading from '@/components/Loading.vue';
// import Statistics from '@/modules/route_manager/components/Statistics.vue';
import InfoModal from '@/components/InfoModal.vue';
import CheckBoxIcon from '@/components/CheckboxIcon.vue';

export default {
  name: 'ModuleLayoutWithRouting',
  components: {
    CheckBoxIcon,
    InfoModal,
    // Statistics,
    Loading,
    Drawer,
    Module,
    Search,
    MapFilter,
    Settings,
    SideNav,
    Map,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      loadingActions: 'settings/getLoadingActions',
      loadingMapObjects: 'settings/getLoadingMapObjects',
      loadingSelections: 'settings/getLoadingSelections',
      loadingSettings: 'settings/getLoadingSettings',
    }),
    loading() {
      console.log('loading', this.loadingActions, this.loadingMapObjects, this.loadingSelections, this.loadingSettings,
        this.loadingActions
        || this.loadingMapObjects
        || this.loadingSelections
        || this.loadingSettings);
      return this.loadingActions
        || this.loadingMapObjects
        || this.loadingSelections
        || this.loadingSettings;
    },
    nav() {
      switch (this.$route.name) {
        case 'route_management':
          return 'routebeheer';
        case 'drawing':
          return 'tekenen';
        case 'selection':
          return 'selecties';
        case 'publication':
          return 'publicaties';
        default:
          return 'routebeheer';
      }
    },
    title() {
      let routeName = this.$route.name;
      if (routeName === 'home') {
        routeName = 'route_management';
      }
      // eslint-disable-next-line prefer-destructuring
      routeName = routeName.split('.')[0];
      return this.$options.filters.capitalize(this.$t(`module.${routeName}`));
    },
    moduleColor() {
      let routeName = this.$route.name;
      // eslint-disable-next-line prefer-destructuring
      routeName = routeName.split('.')[0];
      return `module-${routeName}-color`;
    },
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadingNetwork: false,
      loadingSettingsLocally: false,
      loadingActionsLocally: false,
      loadingMapObjectsLocally: false,
      loadingSelectionsLocally: false,
    };
  },
  async beforeMount() {
    console.log('module layout beforemount');
    this.loadingNetwork = true;
    // this.loadingSettings = true;
    // this.loadingActions = true;
    // this.loadingMapObjects = true;
    // this.loadingSelections = true;
    await this.$store.dispatch('settings/fetchNetwork').then(async () => {
      this.loadingNetwork = false;
      console.log('module layout fetched network');

      // this.$store.dispatch('settings/fetchAllNetwork');

      this.$store.dispatch('settings/fetchMapObjects').then(() => {
        // this.loadingMapObjects = false;
      });
      this.$store.dispatch('settings/fetchActions').then(() => {
        // this.loadingActions = false;
      });
      this.$store.dispatch('settings/fetchSelections').then(() => {
        // this.loadingSelections = false;
      });
      this.$store.dispatch('settings/fetchSettings').then(() => {
        // this.loadingSettings = false;
      });
    });
  },
  watch: {
    // loadingActions(newVal, oldVal) {
    //   console.log('change in loadingActions', newVal, oldVal);
    //   this.loadingActionsLocally = newVal;
    // },
    // loadingMapObjects(newVal, oldVal) {
    //   console.log('change in loadingMapObjects', newVal, oldVal);
    //   this.loadingMapObjectsLocally = newVal;
    // },
    // loadingSelections(newVal, oldVal) {
    //   console.log('change in loadingSelections', newVal, oldVal);
    //   this.loadingSelectionsLocally = newVal;
    // },
    // loadingSettings(newVal, oldVal) {
    //   console.log('change in loadingSettings', newVal, oldVal);
    //   this.loadingSettingsLocally = newVal;
    // },
    // loadingSettingsLocally(newVal, oldVal) {
    //   console.log('change in loadingSettingsLocally', newVal, oldVal);
    // },
  },
};
</script>

<style scoped>
#main {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #dddddd;
}

#map-actions-float {
  position: absolute;
  z-index: -1;
  right: 20px;
  top: 15px;
}

.mgl-map-wrapper {
  left: 80px;
  z-index: -1;
}

#loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  background-color: rgba(155, 155, 155, 0.5);
}
</style>
