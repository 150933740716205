<template>
  <div id="mapContainer" :loadingControl="true">
    <div v-if="map">
      <ContextMenu :map="map" />
      <DrawPoint v-if="drawType === GeoType.point"
                   :map="map"
                   :mode="mode"
                   :map_object_type_id="createObjectTypeId"
                   :object="drawObject"
      />
      <DrawLinestring v-else-if="drawType === GeoType.linestring"
                      :map="map"
                      :mode="mode"
                      :network="network"
                      :object="drawObject"
      />
      <DrawPolygon v-else-if="drawType === GeoType.polygon"
                   :map="map"
                   :mode="mode"
                   :network="network"
                   :object="drawObject"
      />
      <SplitLine v-else-if="mode === MapMode.splitLine"
                 :map="map"
                 :mapObject="mapObject"
      />
      <ShowObject v-else-if="mode === MapMode.objectSelect"
                  :map="map"
      />
      <ShowSelection v-else-if="mode === MapMode.selectionSelect"
                     :map="map"
      />
      <DrawSelection v-else-if="mode === MapMode.selectionCreate ||
                                mode === MapMode.selectionUpdate"
                     :map="map"
                     :selection_type_id="selectionTypeId"
      />
      <CreateMultiSelection v-else-if="mode === MapMode.multiSelectionCreate"
                            :map="map"
      />
<!--      <PrintMap :map="map" />-->
      <ObjectsLayer v-if="mapInitialized" :map="map" :mapMode="mode ? mode : ''" />
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import L from 'leaflet';
import 'leaflet-loading';
import 'leaflet-spin';
import 'leaflet-area-select';
import 'leaflet-easyprint';
import 'leaflet.markercluster';

import { mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import DrawPoint from '@/components/Map/DrawPoint.vue';
import DrawLinestring from '@/components/Map/DrawLinestring.vue';
import DrawPolygon from '@/components/Map/DrawPolygon.vue';
import GeoType from '@/enums/GeoType';
import SplitLine from '@/components/Map/SplitLine.vue';
import ShowSelection from '@/components/Map/ShowSelection.vue';
import ShowObject from '@/components/Map/ShowObject.vue';
// import PrintMap from '@/components/Map/PrintMap.vue';
import DrawSelection from '@/components/Map/DrawSelection.vue';
import ObjectsLayer from '@/components/Map/ObjectsLayer.vue';
import CreateMultiSelection from '@/components/Map/CreateMultiSelection.vue';
import config from '@/util/config';
import ContextMenu from '@/components/Map/ContextMenu.vue';

export default {
  name: 'LeafletMap',
  created() {
  },
  components: {
    ContextMenu,
    CreateMultiSelection,
    DrawSelection,
    // PrintMap,
    ShowObject,
    ShowSelection,
    SplitLine,
    DrawPoint,
    DrawLinestring,
    DrawPolygon,
    ObjectsLayer,
  },
  props: {
    network: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      keyValues: 'settings/getKeyValues',
      mapObjectTypes: 'settings/getMapObjectTypes',
      mapObject: 'selected/getMapObject',
      roles: 'getRoles',
      configUpdatedAt: 'settings/getSettingsLoadedAt',
    }),
    roleId() {
      if (this.roles === null || this.roles === undefined || this.roles.length === 0) {
        return null;
      }
      if (this.roles.length === 1) {
        return this.roles[0].id;
      }
      return this.roles[this.roles.length - 1].id;
    },
    typesOnNetwork() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return [];
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return [];
      }
      return Object.values(this.mapObjectTypes)
        .filter(item => item.on_network)
        .sort(this.sortTypeByGeoType);
    },
  },
  data() {
    return {
      map: null,
      mapInitialized: false,
      mapInitializing: false,
      controlsLocation: 'bottomright',
      tileLayerGeoServer: 'https://geoserver.rapide.nl/styles/osm-liberty/{z}/{x}/{y}.png',
      tileLayerOSM: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileLayerMapBox: `https://api.mapbox.com/styles/v1/rensreinders/ck3yn5u2k0dgo1co8cjovp7dy/tiles/{z}/{x}/{y}?access_token=${config.mapboxAccessToken}`,
      tileLayerPDOK: 'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0',
      wmsUrl: 'http://localhost:8080/geoserver/wms',
      minZoom: 8,
      maxZoom: 19,
      currentZoom: null,
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      selectedObjects: [],
      mode: MapMode.objectSelect,
      createObjectTypeId: 0,
      selectionTypeId: 0,
      drawType: null,
      drawObject: null,
      wmsLayers: {},
      objectsLayer: {},
      linesLayer: {},
      actionsLayer: {},
      showActionsOnMap: true,
      GeoType,
      MapMode,
    };
  },
  methods: {
    sortTypeByGeoType(a, b) {
      if (a.geo_type === 'point' && b.geo_type === 'linestring') {
        return 1;
      }
      if (a.geo_type === 'linestring' && b.geo_type === 'point') {
        return -1;
      }
      return 0;
    },
    initMap() {
      console.log('LeafletMap.initMap()');
      this.mapInitialized = false;
      this.mapInitializing = true;
      L.Map.prototype.setCrs = (newCrs) => {
        this.options.crs = newCrs;
      };
      if (!this.network) {
        return;
      }

      // const tilesGeoServer = L.tileLayer(this.tileLayerGeoServer, {
      //   id: 1,
      //   minzoom: this.minZoom,
      //   maxZoom: this.maxZoom,
      //   loadingControl: true,
      //   zoomControl: false,
      //   fullscreenControl: true,
      //   attribution: this.attribution,
      // });
      const tilesOSM = L.tileLayer(this.tileLayerOSM, {
        id: 2,
        minzoom: this.minZoom,
        maxZoom: this.maxZoom,
        loadingControl: true,
        zoomControl: false,
        fullscreenControl: true,
        attribution: this.attribution,
      });
      const tilesMapBox = L.tileLayer(this.tileLayerMapBox, {
        id: 3,
        minzoom: this.minZoom,
        maxZoom: this.maxZoom,
        loadingControl: true,
        zoomControl: false,
        fullscreenControl: true,
        attribution: this.attribution,
      });
      const tilesPDOK = L.tileLayer.wms(this.tileLayerPDOK, {
        id: 4,
        loadingControl: true,
        zoomControl: false,
        fullscreenControl: true,
        format: 'image/jpeg',
        layers: 'Actueel_ortho25',
        srs: 'EPSG:4326',
      });

      const chosenTileLayer = localStorage.getItem('map_base_layer');
      let baseLayer = [tilesOSM];
      if (chosenTileLayer !== null && chosenTileLayer !== undefined) {
        if (chosenTileLayer === 'OpenStreetMap') {
          baseLayer = [tilesOSM];
        } else if (chosenTileLayer === 'Mapbox') {
          baseLayer = [tilesMapBox];
        } else if (chosenTileLayer === 'Satelliet PDOK') {
          baseLayer = [tilesPDOK];
        }
      }

      this.map = L.map('mapContainer', {
        zoomControl: false,
        // center: [6.56, 53.22],
        // zoom: 13,
        crs: L.CRS.EPSG3857,
        selectArea: false,
        loadingControl: true,
        layers: baseLayer,
      });

      this.fitMapToNetwork();

      L.easyPrint({
        hidden: false,
        tileWait: 100,
        position: this.controlsLocation,
        sizeModes: ['A4Portrait', 'A4Landscape'],
      }).addTo(this.map);

      this.initGpxUpload();

      const zoomControl = L.control.zoom({ position: this.controlsLocation });
      this.map.addControl(zoomControl);

      // this.map.on({
      //   dataloading: this.map.spin(true),
      //   dataload: this.map.spin(false),
      // });

      // Controls
      this.map.addControl(L.Control.loading({
        position: this.controlsLocation,
      }));

      const baseMaps = {
        // GeoServer: tilesGeoServer,
        OpenStreetMap: tilesOSM,
        Mapbox: tilesMapBox,
        'Satelliet PDOK': tilesPDOK,
      };

      // Add tile switcher to map
      L.control.layers(baseMaps, null, {
        position: this.controlsLocation,
      }).addTo(this.map);

      this.map.on('baselayerchange', (e) => {
        localStorage.setItem('map_base_layer', e.name);
      });

      // const gpxUploader = new GPXUpload();
      // gpxUploader.addTo(this.map);
      // console.log('gpxUploader', gpxUploader);
      this.showLoading();

      this.mapInitialized = true;
      this.mapInitializing = false;
    },
    showLoading() {
      this.map.fireEvent('dataloading');
    },
    hideLoading() {
      this.map.fireEvent('dataload');
    },
    fitMapToNetwork() {
      const geoJSON = L.geoJson(this.network.geoObject.data); // .addTo(this.map);
      this.map.setMinZoom(8);
      this.map.setMaxBounds(geoJSON.getBounds().pad(0.7));
      this.map.fitBounds(geoJSON.getBounds(), {
        paddingTopLeft: [460, 10],
        paddingBottomRight: [10, 10],
      });
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        const target = this.map._getBoundsCenterZoom(geoJSON.getBounds());
        this.map.setMinZoom(target.zoom);
      }, 100);
    },
    resetMap() {
      if (this.map !== null) {
        const geoJSON = L.geoJson(this.network.geoObject.data); // .addTo(this.map);
        console.log(geoJSON.getBounds());
        this.map.fitBounds(geoJSON.getBounds());
      }
      // this.initMap();
    },
    getMaxZoom() {
      return this.maxZoom;
    },
    zoomTo(lat, lng, zoom = 16) {
      console.log('zoomTo', lat, lng, zoom);
      this.map.flyTo([lat, lng], zoom, {
        duration: 1,
      });
    },
    zoomToMapObject(mapObject) {
      if (!mapObject || !mapObject.geoObject || !mapObject.geoObject.data) {
        return;
      }
      if (mapObject.geoObject.data.geometry.type === 'Point') {
        const latLng = mapObject.geoObject.data.geometry.coordinates;
        this.zoomTo(latLng[1], latLng[0]);
      } else if (mapObject.geoObject.data.geometry.type === 'LineString') {
        const latLngs = mapObject.geoObject.data.geometry.coordinates;
        const center = new L.LatLngBounds(latLngs).getCenter();
        this.zoomTo(center.lng, center.lat);
      }
    },
    fitBounds(bounds) {
      this.map.fitBounds(bounds);
    },
    initGpxUpload() {
      const GPXUpload = L.Control.extend({
        options: {
          title: 'Upload GPX bestand',
          position: 'bottomright',
          layerForGPX: new L.LayerGroup(),
          gpxFileUploaded: false,
        },
        onAdd(map) {
          const thisControl = this;
          thisControl.options.layerForGPX.addTo(map);

          const container = L.DomUtil.create('div', 'leaflet-control-upload leaflet-control');
          this.link = L.DomUtil.create('a', 'leaflet-control-upload-button leaflet-bar-part', container);
          this.link.title = this.options.title;

          // Create the leaflet control.
          const controlUI = L.DomUtil.create('div', 'leaflet-control-command-interior', container);

          // Create the form inside of the leaflet control.
          const form = L.DomUtil.create('form', 'leaflet-control-command-form', controlUI);
          form.action = '';
          form.method = 'post';
          form.enctype = 'multipart/form-data';

          // Create the input file element.
          const input = L.DomUtil.create('input', 'leaflet-control-command-form-input', form);
          input.id = 'file';
          input.type = 'file';
          input.name = 'uploadFile';
          input.style.display = 'none';

          L.DomEvent
            .addListener(this.link, 'click', () => {
              thisControl.options.layerForGPX.clearLayers();
              document.getElementById('file').value = '';
              document.getElementById('file').click();
            })
            // eslint-disable-next-line func-names
            .addListener(input, 'change', function () {
              thisControl.fileToArrayBuffer(this.files[0]);
            });

          return container;
        },
        addGPXDataToMap: (gpx) => {
          if (gpx instanceof XMLDocument) {
            const wpt = gpx.getElementsByTagName('wpt');
            const wptList = Array.prototype.slice.call(wpt);
            wptList.forEach((waypoint) => {
              const lat = parseFloat(waypoint.getAttribute('lat').slice(0, 8));
              const lon = parseFloat(waypoint.getAttribute('lon').slice(0, 8));
              // eslint-disable-next-line new-cap
              new L.circleMarker([lat, lon], { radius: 5, clickable: false })
                .addTo(this.uploadElement.options.layerForGPX);
            });

            const trkseg = gpx.getElementsByTagName('trkseg');
            const trksegList = Array.prototype.slice.call(trkseg);
            trksegList.forEach((trackseg) => {
              const trackpoints = trackseg.getElementsByTagName('trkpt');
              const coordinates = [];
              const trackpointsList = Array.prototype.slice.call(trackpoints);
              trackpointsList.forEach((trackpoint) => {
                const lat = parseFloat(trackpoint.getAttribute('lat'));
                const lon = parseFloat(trackpoint.getAttribute('lon'));
                coordinates.push([lat, lon]);
              });
              new L.Polyline(coordinates, { color: '#cf00ff', opacity: 0.4, clickable: false })
                .addTo(this.uploadElement.options.layerForGPX);
            });

            const rte = gpx.getElementsByTagName('rte');
            const rteList = Array.prototype.slice.call(rte);
            rteList.forEach((trackseg) => {
              const trackpoints = trackseg.getElementsByTagName('rtept');
              const coordinates = [];
              const trackpointsList = Array.prototype.slice.call(trackpoints);
              trackpointsList.forEach((trackpoint) => {
                const lat = parseFloat(trackpoint.getAttribute('lat'));
                const lon = parseFloat(trackpoint.getAttribute('lon'));
                coordinates.push([lat, lon]);
              });
              new L.Polyline(coordinates, { color: '#cf00ff', opacity: 0.4, clickable: false })
                .addTo(this.uploadElement.options.layerForGPX);
            });
          } else {
            console.log('Geen geldig GPX bestand');
          }
        },
        fileToArrayBuffer: (file) => {
          const reader = new FileReader();

          reader.onloadend = (e) => {
            const result = this.uploadElement.fromXML(e.target.result);
            this.uploadElement.addGPXDataToMap(result);
          };
          reader.readAsText(file);
        },
        fromXML: (xml) => {
          if (typeof (DOMParser) === 'undefined') {
            // eslint-disable-next-line no-global-assign
            DOMParser = () => {};
            DOMParser.prototype.parseFromString = (str, contentType) => {
              let xmldata;
              if (typeof (ActiveXObject) !== 'undefined') {
                // eslint-disable-next-line no-undef
                xmldata = new ActiveXObject('MSXML.DomDocument');
                xmldata.async = false;
                xmldata.loadXML(str);
                return xmldata;
              } if (typeof (XMLHttpRequest) !== 'undefined') {
                xmldata = new XMLHttpRequest();
                xmldata.open('GET',
                  `data:${contentType};charset=utf-8,${encodeURIComponent(str)}`,
                  false);
                if (xmldata.overrideMimeType) {
                  xmldata.overrideMimeType(contentType || 'application/xml');
                }
                xmldata.send(null);
                return xmldata.responseXML;
              }
              return null;
            };
          }

          const parser = new DOMParser();
          const doc = parser.parseFromString(xml, 'text/xml');
          return doc;
        },
      });
      const gpxUpload = new GPXUpload();
      this.uploadElement = gpxUpload;
      gpxUpload.addTo(this.map);
    },
  },
  mounted() {
    if (this.configUpdatedAt) {
      this.initMap();
    }
    EventBus.$on('Map.changeMapMode', (mapMode, geoType, data) => {
      console.log('Map.changeMapMode', mapMode, geoType, data);
      this.mode = mapMode;
      if (mapMode === MapMode.objectCreate) {
        this.drawType = geoType;
        this.createObjectTypeId = data;
        this.drawObject = null;
      } else if (mapMode === MapMode.objectUpdate) {
        this.drawType = geoType;
        this.drawObject = data;
        this.createObjectTypeId = data.map_object_type_id;
      } else if (mapMode === MapMode.objectSelect) {
        this.drawType = null;
        this.drawObject = null;
      } else if (mapMode === MapMode.selectionSelect) {
        this.drawType = null;
        this.drawObject = null;
      } else if (mapMode === MapMode.selectionCreate) {
        this.drawType = null;
        this.selectionTypeId = data;
        this.drawObject = null;
      } else if (mapMode === MapMode.selectionUpdate) {
        this.drawType = null;
        this.selectionTypeId = data;
        this.drawObject = null;
      } else if (mapMode === MapMode.multiSelectionCreate) {
        this.drawType = null;
        this.selectionTypeId = data;
        this.drawObject = null;
      }
    });

    EventBus.$on('Map.redraw', () => {
      // this.redrawWmsLayers();
    });
    // EventBus.$on('Map.loading', (value) => {
    //   this.map.spin(value, { length: 15 });
    // });
    EventBus.$on('Map.loading', (boolean) => {
      if (boolean) {
        this.showLoading();
      } else {
        this.hideLoading();
      }
    });
    EventBus.$on('Map.zoomTo', (lat, lng, zoom = 16) => {
      this.zoomTo(lat, lng, zoom);
    });
    EventBus.$on('Map.zoomToMapObject', (mapObject) => {
      this.zoomToMapObject(mapObject);
    });
    EventBus.$on('Map.fitBounds', (bounds) => {
      this.fitBounds(bounds);
    });
  },
  watch: {
    network(newValue, oldValue) {
      if (!this.configUpdatedAt) {
        return;
      }
      console.log('watch network', this.network, oldValue, newValue, this.mapInitialized, this.mapInitializing);
      if (this.network !== null && !this.mapInitialized && !this.mapInitializing) {
        this.initMap();
      } else {
        this.fitMapToNetwork();
      }
    },
    configUpdatedAt(newValue, oldValue) {
      if (!this.network || !newValue) {
        return;
      }
      console.log('watch configUpdatedAt', this.network, oldValue, newValue, this.mapInitialized, this.mapInitializing);
      if (this.network !== null && !this.mapInitialized && !this.mapInitializing) {
        this.initMap();
      } else {
        this.fitMapToNetwork();
      }
      EventBus.$emit('Map.redraw');
    },
    // mapObjectTypes() {
    //   this.resetMap();
    // },
  },
};
</script>
<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-loading/src/Control.Loading.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

#mapContainer {
  height: 100%;
  width: calc(100% - 80px);
  margin-left: 80px;
  z-index: -1;
}
.leaflet-control-easyPrint a {
  /*background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMTI4LDMyaDI1NnY2NEgxMjhWMzJ6IE00ODAsMTI4SDMyYy0xNy42LDAtMzIsMTQuNC0zMiwzMnYxNjBjMCwxNy42LDE0LjM5OCwzMiwzMiwzMmg5NnYxMjhoMjU2VjM1Mmg5NiAgIGMxNy42LDAsMzItMTQuNCwzMi0zMlYxNjBDNTEyLDE0Mi40LDQ5Ny42LDEyOCw0ODAsMTI4eiBNMzUyLDQ0OEgxNjBWMjg4aDE5MlY0NDh6IE00ODcuMTk5LDE3NmMwLDEyLjgxMy0xMC4zODcsMjMuMi0yMy4xOTcsMjMuMiAgIGMtMTIuODEyLDAtMjMuMjAxLTEwLjM4Ny0yMy4yMDEtMjMuMnMxMC4zODktMjMuMiwyMy4xOTktMjMuMkM0NzYuODE0LDE1Mi44LDQ4Ny4xOTksMTYzLjE4Nyw0ODcuMTk5LDE3NnoiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);*/
  background-size: 16px 16px;
  cursor: pointer;
}

.leaflet-control-upload {
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  border-radius: 4px;
}

.leaflet-control-upload a {
  border-radius: 4px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  width: 26px;
  height: 26px;
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJmaWxlLXVwbG9hZCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWZpbGUtdXBsb2FkIGZhLXctMTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzg0IDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjI0IDEzNlYwSDI0QzEwLjcgMCAwIDEwLjcgMCAyNHY0NjRjMCAxMy4zIDEwLjcgMjQgMjQgMjRoMzM2YzEzLjMgMCAyNC0xMC43IDI0LTI0VjE2MEgyNDhjLTEzLjIgMC0yNC0xMC44LTI0LTI0em02NS4xOCAyMTYuMDFIMjI0djgwYzAgOC44NC03LjE2IDE2LTE2IDE2aC0zMmMtOC44NCAwLTE2LTcuMTYtMTYtMTZ2LTgwSDk0LjgyYy0xNC4yOCAwLTIxLjQxLTE3LjI5LTExLjI3LTI3LjM2bDk2LjQyLTk1LjdjNi42NS02LjYxIDE3LjM5LTYuNjEgMjQuMDQgMGw5Ni40MiA5NS43YzEwLjE1IDEwLjA3IDMuMDMgMjcuMzYtMTEuMjUgMjcuMzZ6TTM3NyAxMDVMMjc5LjEgN2MtNC41LTQuNS0xMC42LTctMTctN0gyNTZ2MTI4aDEyOHYtNi4xYzAtNi4zLTIuNS0xMi40LTctMTYuOXoiPjwvcGF0aD48L3N2Zz4=);*/
  background-size: 16px 16px;
  cursor: pointer;
  background-color: #fff;
}
</style>
<style>
.fade-in {
  opacity: 1;
  animation: fade 0.1s linear;
}
.leaflet-control-layers .leaflet-control-layers-toggle {
  height: 31px;
  width: 31px;
  background-size: 22px;
}

.leaflet-control-upload a {
  border-radius: 4px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  width: 34px;
  height: 34px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJmaWxlLXVwbG9hZCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWZpbGUtdXBsb2FkIGZhLXctMTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzg0IDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjI0IDEzNlYwSDI0QzEwLjcgMCAwIDEwLjcgMCAyNHY0NjRjMCAxMy4zIDEwLjcgMjQgMjQgMjRoMzM2YzEzLjMgMCAyNC0xMC43IDI0LTI0VjE2MEgyNDhjLTEzLjIgMC0yNC0xMC44LTI0LTI0em02NS4xOCAyMTYuMDFIMjI0djgwYzAgOC44NC03LjE2IDE2LTE2IDE2aC0zMmMtOC44NCAwLTE2LTcuMTYtMTYtMTZ2LTgwSDk0LjgyYy0xNC4yOCAwLTIxLjQxLTE3LjI5LTExLjI3LTI3LjM2bDk2LjQyLTk1LjdjNi42NS02LjYxIDE3LjM5LTYuNjEgMjQuMDQgMGw5Ni40MiA5NS43YzEwLjE1IDEwLjA3IDMuMDMgMjcuMzYtMTEuMjUgMjcuMzZ6TTM3NyAxMDVMMjc5LjEgN2MtNC41LTQuNS0xMC42LTctMTctN0gyNTZ2MTI4aDEyOHYtNi4xYzAtNi4zLTIuNS0xMi40LTctMTYuOXoiPjwvcGF0aD48L3N2Zz4=);
  background-size: 16px 16px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
}

@keyframes fade {
  0%   { opacity:0; }
  100% { opacity:1; }
}
</style>
